<template>
    <div class="page-container" v-loading="isLoading">
        <div class="breadcrumb-container">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                                       <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                                       <el-breadcrumb-item><a href="/">文章列表</a></el-breadcrumb-item>
                                       <el-breadcrumb-item>文章详情</el-breadcrumb-item> -->
                <el-breadcrumb-item v-for="(v, i) in activeMenu" :key="i">{{v.name}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="content">
            <div class="content-header pb10" flex="main:justify cross:center">
                <div>
                    <el-button type="primary" @click="add"><svg-icon class="app-mr5" name="add" top="2px"></svg-icon>新增</el-button>
                </div>
                <div class="search-wrap">
                    <el-form :inline="true" :model="search" class="demo-form-inline" ref="searchForm">
                        <el-form-item label="姓名" prop="name">
                            <el-input v-model="search.name" placeholder="请输入姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="所在地区">
                            <el-form-item prop="addr">
                                <el-cascader
                                    placeholder="请选择所在地区"
                                    v-model="search.addr"
                                    :options="opt_addr"
                                >
                                </el-cascader>
                            </el-form-item>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="handleSearch">查询</el-button>
                            <el-button @click="resetSearch">重置</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="content-body">
                <div class="table">
                    <el-table :data="tableData" stripe style="width: 100%" :max-height="tableMaxHeight">
                        <el-table-column label="序号" type="index" :index="mGenTableIndex" width="50">
                        </el-table-column>
                        <el-table-column prop="name" label="姓名" width="180">
                        </el-table-column>
                        <el-table-column label="头像" width="180">
                            <template slot-scope="scope">
                                <el-image
                                    class="app-radius4 app-square50"
                                    :src="mImgBaseUrl + scope.row.thumb"
                                    :preview-src-list="[mImgBaseUrl + scope.row.thumb]"
                                >
                                    <div slot="error">
                                        <img
                                            class="app-square50 app-radius4"
                                            src="../static/no-picture.png"
                                            alt=""
                                        />
                                    </div>
                                </el-image>
                            </template>
                        </el-table-column>
                        <el-table-column prop="gender" label="性别" width="180">
                            <template slot-scope="scope">
                                <span>{{ scope.row.gender | calcGender }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="hobby" label="爱好" width="180">
                        </el-table-column>
                        <el-table-column prop="title" label="职级" width="180">
                        </el-table-column>
                        <el-table-column prop="addr" label="所在地区">
                            <template slot-scope="scope">
                                <span>{{ scope.row.addr | calcAddr }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="join_time"
                            label="入职日期"
                            width="180"
                        >
                        </el-table-column>
                        <el-table-column label="操作" width="200">
                            <template slot-scope="scope">
                                <div>
                                    <el-link
                                        class="app-mr10"
                                        type="primary"
                                        @click="edit(scope.row)"
                                        ><svg-icon name="edit" top="2px"></svg-icon>
                                        编辑</el-link
                                    >
                                    <el-link
                                        class="app-mr10"
                                        type="primary"
                                        @click="view(scope.row)"
                                        ><svg-icon name="view" top="2px"></svg-icon>
                                        查看</el-link
                                    >
                                    <el-link
                                        type="danger"
                                        @click="del(scope)"
                                        ><svg-icon name="delete" top="2px"></svg-icon>
                                        删除</el-link
                                    >
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="pagination app-pr10 app-pt10 app-pb10" flex="main:right">
                    <el-pagination
                        layout="total, prev, pager, next, jumper"
                        :page-size="pagination.pageSize"
                        :total="pagination.total"
                        @current-change="handleChangePage"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>

        <!--新增-->
        <el-dialog
            title="新增"
            :close-on-click-modal="false"
            :visible.sync="showDialogAdd"
            @closed="onDialogClosed"
        >
            <el-form :model="record" ref="form-add" label-width="100px">
                <el-form-item label="姓名" required>
                    <el-form-item prop="name">
                        <el-input v-model="record.name"></el-input>
                    </el-form-item>
                </el-form-item>
                <el-form-item label="头像" required>
                    <el-form-item prop="thumb">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadBaseUrl + '/upload/one'"
                            name="file"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :on-error="handleAvatarFail"
                            :before-upload="beforeAvatarUpload"
                        >
                            <div
                                v-if="record.thumb"
                                class="square75 rel thumb-wrapper"
                            >
                                <img
                                    :src="mImgBaseUrl + record.thumb"
                                    class="abs img-thumb wh100"
                                />
                                <div
                                    class="
                                        thumb-mask
                                        abs
                                        wh100
                                        round
                                        shadow
                                        mask
                                    "
                                    flex="main:center cross:center"
                                >
                                    <svg-icon
                                        name="edit"
                                        class="color-fff opt"
                                    ></svg-icon>
                                </div>
                            </div>
                            <div v-else class="avatar-uploader-icon">
                                <svg-icon name="add-thin"></svg-icon>
                            </div>
                        </el-upload>
                    </el-form-item>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input
                        placeholder="请输入密码"
                        v-model="record.password"
                        show-password
                    ></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="repassword">
                    <el-input
                        placeholder="请再次输入密码"
                        v-model="record.repassword"
                        show-password
                    ></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="gender">
                    <el-radio-group v-model="record.gender">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="爱好" prop="hobby">
                    <el-checkbox-group v-model="record.hobby">
                        <el-checkbox label="西施">西施~</el-checkbox>
                        <el-checkbox label="貂蝉">貂蝉~</el-checkbox>
                        <el-checkbox label="凤姐">凤姐!</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="简介" prop="info">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 3, maxRows: 6 }"
                        v-model="record.info"
                    ></el-input>
                </el-form-item>
                <el-form-item label="职级" prop="title">
                    <el-select v-model="record.title" placeholder="请选择职级">
                        <el-option label="BOSS" value="BOSS"></el-option>
                        <el-option label="CEO" value="CEO"></el-option>
                        <el-option
                            label="工程师"
                            value="web前端高级工程师"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属部门" prop="depart_belong">
                    <el-cascader
                        placeholder="请选择所属部门"
                        v-model="record.depart_belong"
                        :options="opt_depart_belong"
                    >
                    </el-cascader>
                </el-form-item>
                <el-form-item label="入职日期" required>
                    <el-form-item prop="join_time">
                        <el-date-picker
                            type="date"
                            placeholder="请选择入职日期"
                            value-format="yyyy-MM-dd"
                            v-model="record.join_time"
                        ></el-date-picker>
                    </el-form-item>
                </el-form-item>
                <el-form-item label="所在地区" required>
                    <el-form-item prop="addr">
                        <el-cascader
                            placeholder="请选择所在地区"
                            v-model="record.addr"
                            :options="opt_addr"
                        >
                        </el-cascader>
                    </el-form-item>
                </el-form-item>
                <el-form-item label="内容" required>
                    <el-form-item>
                        <textarea
                            name="content"
                            id="content_add"
                            cols="30"
                            rows="10"
                            v-model="record.content"
                        ></textarea>
                    </el-form-item>
                </el-form-item>

                <!-- <el-form-item label="即时配送" prop="delivery">
              <el-switch v-model="record.delivery"></el-switch>
            </el-form-item> -->
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="showDialogAdd = false">取 消</el-button>
                <el-button type="primary" @click="submitAdd">确 定</el-button>
            </div>
        </el-dialog>

        <!--修改-->
        <el-dialog
            title="修改"
            :close-on-click-modal="false"
            :visible.sync="showDialogEdit"
            @closed="onDialogClosed"
        >
            <el-form :model="record" ref="form-edit" label-width="100px">
                <el-form-item label="姓名" required>
                    <el-form-item prop="name">
                        <el-input v-model="record.name"></el-input>
                    </el-form-item>
                </el-form-item>
                <el-form-item label="头像" required>
                    <el-form-item prop="thumb">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadBaseUrl + '/upload/one'"
                            name="file"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :on-error="handleAvatarFail"
                            :before-upload="beforeAvatarUpload"
                        >
                            <div
                                v-if="record.thumb"
                                class="square75 rel thumb-wrapper"
                            >
                                <img
                                    :src="mImgBaseUrl + record.thumb"
                                    class="abs img-thumb wh100"
                                />
                                <div
                                    class="
                                        thumb-mask
                                        abs
                                        wh100
                                        round
                                        shadow
                                        mask
                                    "
                                    flex="main:center cross:center"
                                >
                                    <svg-icon
                                        name="edit"
                                        class="color-fff opt"
                                    ></svg-icon>
                                </div>
                            </div>
                            <div v-else class="avatar-uploader-icon">
                                <svg-icon name="add-thin"></svg-icon>
                            </div>
                        </el-upload>
                    </el-form-item>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input
                        placeholder="请输入密码"
                        v-model="record.password"
                        show-password
                    ></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="repassword">
                    <el-input
                        placeholder="请再次输入密码"
                        v-model="record.repassword"
                        show-password
                    ></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="gender">
                    <el-radio-group v-model="record.gender">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="爱好" prop="hobby">
                    <el-checkbox-group v-model="record.hobby">
                        <el-checkbox label="西施">西施~</el-checkbox>
                        <el-checkbox label="貂蝉">貂蝉~</el-checkbox>
                        <el-checkbox label="凤姐">凤姐!</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="简介" prop="info">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 3, maxRows: 6 }"
                        v-model="record.info"
                    ></el-input>
                </el-form-item>
                <el-form-item label="职级" prop="title">
                    <el-select v-model="record.title" placeholder="请选择职级">
                        <el-option label="BOSS" value="BOSS"></el-option>
                        <el-option label="CEO" value="CEO"></el-option>
                        <el-option
                            label="工程师"
                            value="web前端高级工程师"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属部门" prop="depart_belong">
                    <el-cascader
                        placeholder="请选择所属部门"
                        v-model="record.depart_belong"
                        :options="opt_depart_belong"
                    >
                    </el-cascader>
                </el-form-item>
                <el-form-item label="入职日期" required>
                    <el-form-item prop="join_time">
                        <el-date-picker
                            type="date"
                            placeholder="请选择入职日期"
                            value-format="yyyy-MM-dd"
                            v-model="record.join_time"
                        ></el-date-picker>
                    </el-form-item>
                </el-form-item>
                <el-form-item label="所在地区" required>
                    <el-form-item prop="addr">
                        <el-cascader
                            placeholder="请选择所在地区"
                            v-model="record.addr"
                            :options="opt_addr"
                        >
                        </el-cascader>
                    </el-form-item>
                </el-form-item>
                <el-form-item label="内容" required>
                    <el-form-item>
                        <textarea
                            name="content"
                            id="content_edit"
                            cols="30"
                            rows="10"
                            v-model="record.content"
                        ></textarea>
                    </el-form-item>
                </el-form-item>

                <!-- <el-form-item label="即时配送" prop="delivery">
              <el-switch v-model="record.delivery"></el-switch>
            </el-form-item> -->
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="showDialogEdit = false">取 消</el-button>
                <el-button type="primary" @click="submitEdit">确 定</el-button>
            </div>
        </el-dialog>
        
        <!--查看-->
        <el-dialog
            title="查看"
            :close-on-click-modal="false"
            :visible.sync="showDialogView"
        >   
            <div class="form-container app-rel">
                <el-form :model="record" ref="form-view" label-width="100px">
                    <el-form-item label="姓名" required>
                        <el-form-item prop="name">
                            <el-input v-model="record.name"></el-input>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item label="头像" required>
                        <el-form-item prop="thumb">
                            <el-upload
                                class="avatar-uploader"
                                :action="uploadBaseUrl + '/upload/one'"
                                name="file"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :on-error="handleAvatarFail"
                                :before-upload="beforeAvatarUpload"
                            >
                                <div
                                    v-if="record.thumb"
                                    class="square75 rel thumb-wrapper"
                                >
                                    <img
                                        :src="mImgBaseUrl + record.thumb"
                                        class="abs img-thumb wh100"
                                    />
                                    <div
                                        class="
                                            thumb-mask
                                            abs
                                            wh100
                                            round
                                            shadow
                                            mask
                                        "
                                        flex="main:center cross:center"
                                    >
                                        <svg-icon
                                            name="edit"
                                            class="color-fff opt"
                                        ></svg-icon>
                                    </div>
                                </div>
                                <div v-else class="avatar-uploader-icon">
                                    <svg-icon name="add-thin"></svg-icon>
                                </div>
                            </el-upload>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input
                            placeholder="请输入密码"
                            v-model="record.password"
                            show-password
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="repassword">
                        <el-input
                            placeholder="请再次输入密码"
                            v-model="record.repassword"
                            show-password
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="性别" prop="gender">
                        <el-radio-group v-model="record.gender">
                            <el-radio :label="1">男</el-radio>
                            <el-radio :label="2">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="爱好" prop="hobby">
                        <el-checkbox-group v-model="record.hobby">
                            <el-checkbox label="西施">西施~</el-checkbox>
                            <el-checkbox label="貂蝉">貂蝉~</el-checkbox>
                            <el-checkbox label="凤姐">凤姐!</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="简介" prop="info">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 3, maxRows: 6 }"
                            v-model="record.info"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="职级" prop="title">
                        <el-select v-model="record.title" placeholder="请选择职级">
                            <el-option label="BOSS" value="BOSS"></el-option>
                            <el-option label="CEO" value="CEO"></el-option>
                            <el-option
                                label="工程师"
                                value="web前端高级工程师"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属部门" prop="depart_belong">
                        <el-cascader
                            placeholder="请选择所属部门"
                            v-model="record.depart_belong"
                            :options="opt_depart_belong"
                        >
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="入职日期" required>
                        <el-form-item prop="join_time">
                            <el-date-picker
                                type="date"
                                placeholder="请选择入职日期"
                                value-format="yyyy-MM-dd"
                                v-model="record.join_time"
                            ></el-date-picker>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item label="所在地区" required>
                        <el-form-item prop="addr">
                            <el-cascader
                                placeholder="请选择所在地区"
                                v-model="record.addr"
                                :options="opt_addr"
                            >
                            </el-cascader>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item label="内容" required>
                        <el-form-item>
                            <textarea
                                name="content"
                                id="content_view"
                                cols="30"
                                rows="10"
                                v-model="record.content"
                            ></textarea>
                        </el-form-item>
                    </el-form-item>

                    <!-- <el-form-item label="即时配送" prop="delivery">
                <el-switch v-model="record.delivery"></el-switch>
                </el-form-item> -->
                </el-form>
                <div class="form-mask"></div>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="showDialogView = false">关 闭</el-button>
            </div>
        </el-dialog>
        <PanelDrawer :show="showAdd"></PanelDrawer>
    </div>
</template>

<script>
import { regionData } from "element-china-area-data";
// import {mapMutations, mapState, mapGetters, mapActions} from 'vuex';
import { mapActions, mapMutations, mapState } from "vuex";
import PanelDrawer from '../components/PanelDrawer.vue';

export default {
    name: "article-list",
    components: {
        PanelDrawer
    },
    data() {
        return {
            showDialogCloseTip: false, 
            tableMaxHeight: 0,
            pagination: {
                page: 1,
                total: 0,
                pageSize: 15
            },
            search: {
                name: '',
                addr: [] 
            },
            record: {
                name: "",
                thumb: "",
                password: "",
                repassword: "",
                gender: 1,
                hobby: [],
                info: "",
                content: "",
                title: "", // 职级
                depart_belong: [],
                join_time: "",
                addr: [],
            },

            opt_depart_belong: [
                {
                    label: "国防部",
                    value: "gfb",
                    children: [
                        {
                            label: "核心小组",
                            value: "hxxz",
                        },
                        {
                            label: "酱油组",
                            value: "jyz",
                        },
                    ],
                },
                {
                    label: "农业部",
                    value: "nyb",
                    children: [
                        {
                            label: "养猪办",
                            value: "yzb",
                        },
                        {
                            label: "种地办",
                            value: "zdb",
                        },
                    ],
                },
            ],
            opt_addr: regionData,

            showDialogAdd: false,
            showDialogEdit: false,
            showDialogView: false,
            tableData: [],
            

            showAdd: false,
        };
    },
    computed: {
        ...mapState(["isLoading", "uploadBaseUrl", "activeMenu"]),
    },
    created() {
        this.getData();
    },
    mounted() {
        // this.record = {
        //     name: "丁禹",
        //     // thumb: "https://dss0.bdstatic.com/6Ox1bjeh1BF3odCf/it/u=4191265673,2281481057&fm=218&app=92&f=JPEG?w=121&h=75&s=C0B335D41CBB42940C08611A0300A060",
        //     thumb: "",
        //     password: "111111",
        //     repassword: "111111",
        //     gender: 1,
        //     hobby: ["西施", "貂蝉"],
        //     info: "简介",
        //     content: "<p>content here!</p>\n",
        //     title: "BOSS",
        //     depart_belong: ["gfb", "hxxz"],
        //     join_time: "2021-05-28",
        //     addr: ["510000", "510100", "510107"],
        // };
        // window.CKEDITOR.replace("content", {
        //   filebrowserImageUploadUrl: "http://test.my/upload.php",
        //   height: 500,
        // });
    },
    methods: {
        ...mapMutations(["disableLoading"]),
        resetSearch() {
            this.$refs['searchForm'].resetFields();
            this.getData();
        },
        handleSearch() {
            this.getData();
        },
        onDialogClosed() {
            if(this.showDialogCloseTip) {
                this.mToast('操作成功', 'success');
                this.disableLoading();
                this.getData();
                this.showDialogCloseTip = false;
            }
        },
        initRecord() {
            this.record = {
                name: "",
                thumb: "",
                password: "",
                repassword: "",
                gender: 1,
                hobby: [],
                info: "",
                content: "",
                title: "", // 职级
                depart_belong: [],
                join_time: "",
                addr: [],
            };
        },
        formatRecord(record) {
            this.record = Object.assign({}, this.record, record);
            this.record.addr = this.record.addr.split(',');
            this.record.depart_belong = this.record.depart_belong.split(',');
            this.record.hobby = this.record.hobby.split(',');
        },
        handleChangePage(page) {
            console.log('page', page)
            this.pagination.page = page;
            this.getData();
        },
        getData() {
            let params = { page: this.pagination.page, ...this.search };
            // this.disableLoading();
            this.$api.article.getArticle(params).then(
                (res) => {
                    this.tableMaxHeight = this.calcTableHeight();
                    this.tableData = res.items;
                    this.pagination.total = res._meta.totalCount;
                    this.pagination.pageSize = res._meta.perPage;
                },
                (err) => console.log(err)
            );
        },
        calcTableHeight() {
          return document.getElementsByClassName('page-container')[0].clientHeight - document.getElementsByClassName('breadcrumb-container')[0].clientHeight - document.getElementsByClassName('content-header')[0].clientHeight - document.getElementsByClassName('pagination')[0].clientHeight;
        },
        handleAvatarFail(err) {
            if (typeof err.message === "string") {
                err.message = JSON.parse(err.message);
            }
            this.mToast(err.message.message, "error");
        },
        handleAvatarSuccess(res) {
            this.record.thumb = res;
        },
        beforeAvatarUpload(file) {
            // 可限制图片类型及大小
            // const isJPG = file.type === 'image/jpeg';
            // const isLt2M = file.size / 1024 / 1024 < 2;
            // if (!isJPG) {
            // this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            // if (!isLt2M) {
            // this.$message.error('上传头像图片大小不能超过 2MB!');
            // }
            // return isJPG && isLt2M;
        },
        initRichText(id) {
            if (window.CKEDITOR.instances[id]) {
                window.CKEDITOR.instances[id].destroy();
            }
            this.$nextTick(function () {
                window.CKEDITOR.replace(id, {
                    filebrowserImageUploadUrl:
                        this.uploadBaseUrl + "/upload/ck-upload-img",
                    height: 500,
                });
                // 滚动至dialog顶部
                document.getElementsByClassName(
                    "el-dialog__wrapper"
                )[0].scrollTop = 0; // add
                document.getElementsByClassName(
                    "el-dialog__wrapper"
                )[1].scrollTop = 0; // edit
            });
        },
        getRichTextValue(id) {
            if (window.CKEDITOR.instances[id]) {
                return window.CKEDITOR.instances[id].getData();
            }

            return null;
        },
        submitAdd() {
            // 验证
            let content = this.getRichTextValue("content_add");
            if (!content) {
                this.mToast("内容不能为空哦", "error");
                return false;
            } else {
                this.record.content = content;
            }

            this.$utils.logJson(this.record);
            this.disableLoading();
            this.$request.post("demo", this.record).then(
                () => {
                    this.$refs["form-add"].resetFields();
                    this.showDialogAdd = false;
                    this.showDialogCloseTip = true;
                },
                (err) => {
                    this.mToast(err.data[0]["message"], "error");
                }
            );
        },
        submitEdit() {
            // 验证
            let content = this.getRichTextValue("content_edit");
            if (!content) {
                this.mToast("内容不能为空哦", "error");
                return false;
            } else {
                this.record.content = content;
            }
            
            this.disableLoading();
            this.$request.put("demo/" + this.record.id, this.record).then(
                () => {
                    this.$refs["form-edit"].resetFields();
                    this.showDialogEdit = false;
                    this.showDialogCloseTip = true;
                },
                (err) => {
                    this.mToast(err.data[0]["message"], "error");
                }
            );
        },
        add() {
            
            this.showAdd = true;

            // this.initRecord();
            // this.showDialogAdd = true;
            // this.initRichText("content_add");
        },
        view(record) {
            this.formatRecord(record);
            this.showDialogView = true;
            this.initRichText("content_view");
        },
        edit(record) {
            this.formatRecord(record);
            this.showDialogEdit = true;
            this.initRichText("content_edit");
        },
        del(scope) {
            this.mConfirm('确定删除?', () => {
                this.$request.delete('demo/' + scope.row.id).then(res => {
                    this.mToast('操作成功', 'success');
                    this.getData();
                }); 
            });
        },
    },
};
</script>

<style scoped lang="less">
.page-container {
    height: calc(100vh - @height-header);
    .form-mask{
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 999;
    }
    .breadcrumb-container {
        padding: 20px 10px;
    }
    .content {
        padding: 0 10px 20px 10px;
    }
    .avatar-uploader {
        line-height: 0;
        .thumb-wrapper {
            img {
                left: 0;
                top: 0;
            }
            .thumb-mask {
                display: none;
            }
            &:hover {
                .thumb-mask {
                    display: flex;
                }
            }
        }
        .avatar-uploader-icon {
            width: 75px;
            height: 75px;
            line-height: 75px;
            text-align: center;
            border: dashed 1px @border-color-1;
            color: #999;
            &:hover {
                border-color: @color-primary;
                color: @color-primary;
            }
        }
    }
}
</style>